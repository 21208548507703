import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import './SolutionsCard.css'
function ElectronicsCard() {
    return (
        // <div className='cards'>
        //     <div className='cards__container'>
        //         <div className='cards__wrapper'>
        //             <ul className='cards__items'>
        //                 <CardItem
        //                     src='images/img-3.jpg'
        //                     text='Software Solutions'
        //                 />
        //                 <CardItem
        //                     src='images/img-4.jpg'
        //                     text='Enterprise Solution'
        //                 />
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Electronics Equipment Sales'
        //                 />
        //             </ul>
        //             <ul className='cards__items'>
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Electronics Equipment Sales'
        //                 />
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Electronics Equipment Sales'
        //                 />
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Electronics Equipment Sales'
        //                 />
        //             </ul>
        //         </div>
        //     </div>
        // </div>
        <div className='solutions'>
            <div className='solutions__container'>
                <div className='solutions__wrapper'>
                    <h2>Smart Building Solutions</h2>
                    <div className='solutions_items'>
                        <div className='solutions__info'>
                            <p>Technology is a great ally to optimize the resources of a building and to improve its security.
                                Elevate your building experiance with our Smart Building Solutions, designed for building owners and office
                                occupants seeking to minimize energy consumption, control applicances remotely, and cut operational costs.
                                We connect building systems, implement centralized control, and integrate IOT sensors through a cutting-edge
                                platform, revolutionizing building management beyond traditional systems.
                                <br />
                                <ul style={{ marginLeft: 40, marginTop: 10 }}>
                                    <li><p>Central Computer Room/Data Center</p></li>
                                    <li><p>Structural Cabling Systems</p></li>
                                    <li><p>Public Addressing Systems</p></li>
                                    <li><p>Digital Signage Systems</p></li>
                                    <li><p>IPTV Systems</p></li>
                                    <li><p>Fire Alarm Systems and</p></li>
                                    <li><p>Building Automation Systems</p></li>
                                </ul>
                            </p>
                        </div>
                        <div className='solutions__img'>
                            <img src='/images/SmartBuilding.jpg' />
                        </div>
                    </div>
                    <h2>Digital Conference Solutions & Audio-Visual Solutions</h2>
                    <div className='solutions_items'>
                        <div className='solutions__info'>
                            <p>
                                Our Conference Halls/Rooms AV Solutions empower organizations with the objective of facilitating large or
                                small meetings and discussions, where conference halls and rooms offer dedicated spaces for holding meetings,
                                discussions, and peresentations. We empower organizations by designing, supplying, and installing end-to-end
                                state-of-the-art integrated Audio-Visual Equipment for Conference Halls/Rooms or Meeting Rooms.
                                <br />
                                Supply of complete Audio-video conference system solutions, including,
                                <br />
                                <ul style={{ marginLeft: 40, marginTop: 10 }}>
                                    <li><p>Conference Discussion System</p></li>
                                    <li><p>Projection System</p></li>
                                    <li><p>Professional Sound Systems</p></li>
                                    <li><p>Simultaneous Interpretation System</p></li>
                                    <li><p>Attendance Registration System</p></li>
                                    <li><p>Multimedia Control System and</p></li>
                                    <li><p>Conference Management Software</p></li>
                                </ul>
                            </p>
                        </div>
                        <div className='solutions__img'>
                            <img src='/images/AudioVisualSolution.png' />
                        </div>
                    </div>

                    <h2>Shared Core Banking Solutions for Microfinance</h2>
                    <div className='solutions_items'>
                        <div className='solutions__info'>
                            <p>
                                Our Cloud-based Core Banking Solutions empower Microfinance institutions whose primary objective is provide
                                financial access for individuals and small businessess. We empower Microfinance by minimizing upfront
                                investment through Shared Cloud Services & Scalable Options, Reducing operating cost through digitalization,
                                and ensuring competitiveness in the ever-evolving technology landscape.
                            </p>
                        </div>
                        <div className='solutions__img'>
                            <img src='/images/CoreBanking.jpg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ElectronicsCard;
