import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSectionSoftware() {
  return (
    <div className='hero-container'>
      <img className='hero-img' src='/images/SoftwareProjectHeader.jpg'  />
      <h1>Our Software Projects</h1>
    </div>
  );
}

export default HeroSectionSoftware;
