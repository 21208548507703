import React, {useEffect} from "react";
import '../../App.css';
import Cards from '../HomeCards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import About from '../AboutCard';
import OurEmployee from '../OurEmployee';
function Home() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <>
      <HeroSection />
      <About />
      <Cards />
      <OurEmployee />
      <Footer />
    </>
  );
}

export default Home;
