import React, {useEffect} from "react";
import EnterpriseCard from "../EnterpriseCard";
import Footer from "../Footer";
import HeroSectionEnterprise from "../HeroSectionEnterprise";

function EnterpriseSolution (){
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return(
        <>
        <HeroSectionEnterprise />
        <EnterpriseCard />
        <Footer />
        </>
    )
}
 export default EnterpriseSolution