import React, { useEffect } from "react";
import HeroSection from '../HeroSectionAbout';
import About from '../AboutCard';
import '../../App.css';
import Footer from '../Footer';
import OurEmployee from "../OurEmployee";
function AboutUs() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return (
        <>
            <HeroSection />
            <About />
            <OurEmployee />
            <Footer />
        </>
    )
}
export default AboutUs