import React, {useEffect} from "react";
import HeroSection from '../HeroSectionProjects';
import About from '../AboutCard';
import Cards from '../HomeCards';
import '../../App.css';
import Footer from '../Footer';
import ProjectsCard from "../ProjectsCard";
function ServiceAndProducts() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return (
        <>
            <HeroSection />
            <ProjectsCard />
            <Footer />
        </>
    )
}
export default ServiceAndProducts