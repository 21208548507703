import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './components/pages/AboutUs'
import Projects from './components/pages/Projects'
import Contact from './components/pages/ContactUs'
import Software from './components/pages/SoftwareSolution'
import Enterprise from './components/pages/EnterpriseSolution'
import Solutions from './components/pages/Solutions'
import InvesetWithUs from './components/pages/InvesetWithUs'
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' component={About} />
          <Route path='/solutions' component={Solutions} />
          <Route path='/contact-us' component={Contact} />
          <Route path='/software-projects' component={Software} />
          <Route path='/enterprise-projects' component={Enterprise} />
          <Route path='/projects' component={Projects} />
          <Route path='/invest-with-us' component={InvesetWithUs} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
