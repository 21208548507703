import React, { useEffect } from "react";
import HeroSection from '../HeroSectionContact';
import About from '../AboutCard';
import '../ContactUs.css';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import '../Button.css'
function ContactUs() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <HeroSection />
            {/* <div className='contact__us'>
                <h1>Do not hesitate to contact us</h1>
                <div className='contact__items'>
                    <div className='contact__item'>
                        <div className='contact__info'>
                            <i class='fa fa-thumbtack' style={{ color: 'black', paddingRight: '10px', paddingTop: 3 }} />
                            <p>4th Floor Duki Building Office Suit 402 St. Yared Square, Addis Ababa</p>
                        </div>
                        <div className='contact__info'>
                            <i class='fa fa-phone' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
                            <p>Management : +251-911-513683</p>
                        </div>
                        <div className='contact__info'>
                            <i class='fa fa-phone' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
                            <p>Finance Dept : +251-911-140743</p>
                        </div>
                        <div className='contact__info'>
                            <i class='fa fa-phone' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
                            <p>Marketing : +251-965-299202</p>
                        </div>
                        <div className='contact__info'>
                            <i class='fa fa-envelope' style={{ color: 'black', paddingRight: '10px', paddingTop: 3 }} />
                            <p>Poiict.ethiopia@gmail.com</p>
                        </div>
                    </div>
                    <div className='contact__item'>
                        <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                            <p>Follow Us</p>
                        </div>
                        <div className='social__medias'>
                            <Link
                                class='contact-social-icon-link linkedin'
                                to='/'
                                target='_blank'
                                aria-label='Linkedin'
                            >
                                <i class='fab fa-linkedin' />
                            </Link>
                            <Link
                                class='contact-social-icon-link telegram'
                                to='/'
                                target='_blank'
                                aria-label='Telegram'
                            >
                                <i class='fab fa-telegram' />
                            </Link>
                            <Link
                                class='contact-social-icon-link whatsapp'
                                to='/'
                                target='_blank'
                                aria-label='Whatsapp'
                            >
                                <i class='fab fa-whatsapp' />
                            </Link>
                            <Link
                                class='social-icon-link youtube'
                                to='/'
                                target='_blank'
                                aria-label='Youtube'
                            >
                                <i class='fab fa-youtube' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="contact__container" >
                <div className="card contact">
                    <div className="contact__header" >
                        <h2>Get in Touch</h2>
                    </div>
                    <div>
                        <div className="contact__item" >
                            <label for="email"
                                className="">
                                Your email
                            </label>
                            <input type="email"
                                className=""
                                placeholder="abc@gmail.com"
                                required />
                        </div>
                        <div className="contact__item" >
                            <label
                                className="" >
                                Subject
                            </label>
                            <input type="email"
                                className=""
                                placeholder="What issue/suggestion do you have?"
                                required />
                        </div>
                        <div className="contact__item" >
                            <label
                                className="" >
                                Your message
                            </label>
                            <textarea rows="6"
                                className=""
                                placeholder="Query/Suggestion..." />
                        </div>
                        <div className="contact__button" >
                            <button type="submit"
                                className="btn--outline">
                                Send message
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="py-2 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl font-extrabold  
                           text-center text-gray-900">
                    Contact Us
                </h2>
                <p className="mb-4 font-light text-left  
                          text-gray-500 sm:text-xl">
                    Got a issue? Want to send feedback?
                    Need details about our Courses? Let us know.
                </p>
                <form action="#">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label for="email"
                            className="block my-2 text-left text-sm  
                                      font-medium text-gray-900">
                            Your email
                        </label>
                        <input type="email"
                            className="shadow-sm bg-gray-50 border  
                                      border-gray-300 text-gray-900  
                                      text-sm rounded-lg block w-full p-2.5"
                            placeholder="abc@geeksforgeeks.org"
                            required />
                    </div>
                    <div>
                        <label for="subject"
                            className="block my-2 text-left  
                                      text-sm font-medium text-gray-900">
                            Subject
                        </label>
                        <input type="text"
                            className="block p-3 w-full text-sm  
                                      text-gray-900 bg-gray-50 rounded-lg  
                                      border border-gray-300 shadow-sm "
                            placeholder="What issue/suggestion do you have?"
                            required />
                    </div>
                    <div >
                        <label for="message"
                            className="block my-2 text-left  
                                      text-sm font-medium text-gray-900 ">
                            Your message
                        </label>
                        <textarea rows="6"
                            className="block p-2.5 w-full text-sm  
                                         text-gray-900 bg-gray-50 rounded-lg  
                                         shadow-sm border border-gray-300 "
                            placeholder="Query/Suggestion..." />
                    </div>
                    <button type="submit"
                        className="mt-2 p-2 float-right text-white   
                                   rounded-lg border-green-600  
                                   bg-green-600 hover:scale-105">
                        Send message
                    </button>
                </form>
            </div> */}
            <Footer />
        </>
    )
}
export default ContactUs