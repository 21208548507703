import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSectionEnterprise() {
  return (
    <div className='hero-container'>
      <img className='hero-img' src='/images/EnterpriseProjectHeader.jpg'  />
      <h1>Our Enterprise Projects</h1>
    </div>
  );
}

export default HeroSectionEnterprise;
