import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import './SolutionsCard.css'

function SoftwareCard() {
    return (
        // <div className='cards'>
        //     <div className='cards__container'>
        //         <div className='cards__wrapper'>
        //             <ul className='cards__items'>
        //                 <CardItem
        //                     src='images/img-3.jpg'
        //                     text='Emanda E-Commerce Software'
        //                 />
        //                 <CardItem
        //                     src='images/img-4.jpg'
        //                     text='Emanda Shareholder Voting System'
        //                 />
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Emanda Share Selling System'
        //                 />
        //             </ul>
        //             <ul className='cards__items'>
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Emanda MFI Cloud-Based Solution'
        //                 />
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Emanda MFI Cloud-Based Solution'
        //                 />
        //                 <CardItem
        //                     src='images/img-8.jpg'
        //                     text='Emanda MFI Cloud-Based Solution'
        //                 />
        //             </ul>
        //         </div>
        //     </div>
        // </div>
        <div className='solutions'>
        <div className='solutions__container'>
            <div className='solutions__wrapper'>
                <h2>Shared Core Banking Solutions for Microfinance</h2>
                <div className='solutions_items'>
                    <div className='solutions__info'>
                        <p>
                            Our Cloud-based Core Banking Solutions empower Microfinance institutions whose primary objective is provide
                            financial access for individuals and small businessess. We empower Microfinance by minimizing upfront
                            investment through Shared Cloud Services & Scalable Options, Reducing operating cost through digitalization,
                            and ensuring competitiveness in the ever-evolving technology landscape. 
                        </p>
                    </div>
                    <div className='solutions__img'>
                        <img src='/images/CoreBanking.jpg' />
                    </div>
                </div>
                <h2>Emanda E-Commerce Web-Based Project</h2>
                <div className='solutions_items'>
                    
                    <div className='solutions__info'>
                        <p>
                            Our Emanda E-Commerce Platform is a place where you can find all electronics related item all in one. We design our
                            web-based platform to enable both electronics sellers and buyers to sale and buy their items on our system. Our system 
                            enable business to increase their reach and market share, enhance efficency, gives detailed customer insights and flexibly sold 
                            their products and for the buyers, it increases accessibility of products, gives wider product selection, provides detail product
                            information and provides faster and easier transaction.
                        </p>
                    </div>
                    <div className='solutions__img'>
                        <img src='/images/Ecommerce.webp' />
                    </div>
                </div>
                <h2>Emanda Board Member Voting System</h2>
                <div className='solutions_items'>
                    <div className='solutions__info'>
                        <p>
                            Our Shareholder Voting System is designed for a newly organized Microfinance institution, to automate the whole board members
                            election system. Our system has been in practical use for the institution and its successfully resolve the struggle and time consumption 
                            traditional voting system most institutions has been using. Beyond the voting module, the system encompasses :
                            <br/>
                            <ul style={{marginLeft: 40, marginTop: 10}}>
                                <li><p>Shareholder Registration Module</p></li>
                                <li><p>Shareholder Attendance Module </p></li>
                                <li><p>Live Vote Result Display Module</p></li>
                                <li><p>Board Members Registration Module</p></li>
                             </ul>
                        </p>
                    </div>
                    <div className='solutions__img'>
                        <img src='/images/VotingSystem.jpg' />
                    </div>
                </div>
                <h2>Ashara Share-Selling System</h2>
                <div className='solutions_items'>
                    <div className='solutions__info'>
                        <p>
                            Ashara Share-Selling System were build for a Microfinance institution that is in development and our system aims to automate their
                            traditional share-selling method. By simply registaring the invited potential buyer to the system, our system allows potential buyers 
                            to buy any amount of shares, allowed by the institution, by simply clicking two buttons.  
                             <br/>
                             Our system includes,
                             <br/>
                             <ul style={{marginLeft: 40, marginTop: 10}}>
                                <li><p>Simple Invited Buyers Registration</p></li>
                                <li><p>Live Share Sold and Share Buyers Display</p></li>
                                <li><p>Share Sold Report Module</p></li>
                             </ul>
                        </p>
                    </div>
                    <div className='solutions__img'>
                        <img src='/images/ShareSelling1.png' />
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default SoftwareCard;
