import React, {useEffect} from "react";
import Footer from "../Footer";
import HeroSectionSoftware from "../HeroSectionSoftware";
import SoftwareCard from "../SoftwareCard";

function SoftwareSolution (){
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return(
        <>
        <HeroSectionSoftware />
        <SoftwareCard />
        <Footer />
        </>
    )
}
 export default SoftwareSolution