import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function ProjectsCard() {
  return (
    <div className='cards'>
      <div className='cards__container' style={{marginBottom: 70, marginTop: 40}}>
        <div className='cards__wrapper' >
          <ul className='cards__items'>
            <CardItem
              src='images/SoftwareProjectCard.jpg'
              text='Software Projects'
              path='/software-projects'
            />
            <CardItem
              src='images/EnterpriseProjectCard.png'
              text='Enterprise Projects'
              path='/enterprise-projects'
            />
            {/* <CardItem
              src='images/img-8.jpg'
              text='Enterprise Projects'
              path='/enterprise-services'
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProjectsCard;
