import React from "react";
import HeroSectionInveset from "../HeroSectionInveset";
import Footer from "../Footer";
import '../SolutionsCard.css'

function InvesetWithUs() {
    return (
        <>
            <HeroSectionInveset />
            <div className='solutions'>
                <div className='solutions__container'>
                    <div className='solutions__wrapper'>
                        <div className='solutions_items'>
                            <div className='solutions__info'>
                                <p>
                                    <p style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom:0 }}>A Compelling Investment: The Future Lies in Technology</p>
                                    <br/>
                                    As we stand at the precipice of a technologically driven future, discerning investors seek opportunities poised for exponential growth.
                                    Today, I invite you to consider an investment in P.O.I, a technology company poised to disrupt the ICT industry with its groundbreaking ICT based Solutions and visionary leadership.
                                    <br />
                                    <p style={{ fontWeight: 'bold', paddingTop: 10 }}>Unveiling a Compelling Need:</p>
                                    <br />
                                    P.O.I addresses a critical need in the technology industry by tackling peoples day to day lives with its innovative technological solutions.
                                    <br />
                                    <p style={{ fontWeight: 'bold', paddingTop: 10 }}>Poised for Growth:</p>
                                    <br />
                                    The market for our ICT Solutions is experiencing explosive growth. We are strategically positioned to capitalize on this growth with our first-mover advantage,
                                    strong intellectual property portfolio, and a dedicated team of industry experts.
                                    <br />
                                    <p style={{ fontWeight: 'bold', paddingTop: 10 }}>Leadership and Execution:</p>
                                    <br />
                                    Our experienced leadership team boasts a proven track record of success in the technology industry.
                                    They are backed by a talented workforce passionate about innovation and committed to delivering exceptional results.
                                    This combination of visionary leadership and skilled execution ensures that P.O.I is positioned for long-term success.
                                    <br />
                                    <p style={{ fontWeight: 'bold', paddingTop: 10 }}>Investment Opportunity:</p>
                                    <br />
                                    By investing in P.O.I, you are not just investing in a technology; you are investing in the future.
                                    We offer a unique opportunity to be a part of a revolutionary company that is shaping the landscape of the ICT industry.
                                    We invite you to join us on this exciting journey and witness the incredible potential that lies ahead.
                                    <br />
                                    <p style={{ fontWeight: 'bold', paddingTop: 10 }}>A Glimpse into the Future:</p>
                                    <br />
                                    Investing in P.O.I Information Technology Services is not just about financial returns; it is about being a part of something bigger.
                                    We believe our technology solutions has the power to solve problems our country is facing regarding ICT.
                                    With your support, we can make this vision a reality and create a brighter future for everyone.
                                    <br />
                                    <p style={{ fontWeight: 'bold', paddingTop: 25 }}>Join us today and invest in the future of technology.</p>
                                </p>
                            </div>
                            {/* <div className='about__img'>
                                <img src='/images/img-home.jpg' />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default InvesetWithUs