import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from 'react-fontawesome';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer__items'>
        <div className='footer__item'>
          <div>
            <Link to='/' className='footer-logo'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              {/* POI
              <i class='fab fa-typo3' /> */}
              <img src='/images/POILogo.png' />
            </Link>
          </div>
          <div className='header__content'>
            <Link
              className='header__links'
              to='/'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <p>Home</p>
            </Link>
            <p>|</p>
            <Link
              className='header__links'
              to='/about'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <p>About Us</p>
            </Link>
            <p>|</p>
            <Link
              className='header__links'
              to='/solutions'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <p>Solutions</p>
            </Link>
            <p>|</p>
            <Link
              className='header__links'
              to='/projects'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <p>Projects</p>
            </Link>
            <p>|</p>
            <Link
              className='header__links'
              to='/inveset-with-us'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <p>Invest With Us</p>
            </Link>
            <p>|</p>
            <Link
              className='header__links'
              to='/contact-us'
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <p>Contact Us</p>
            </Link>
          </div>

        </div>
        <div className='footer__item'>
          <div className='office_address' style={{ fontWeight: 'bold', paddingBottom: '5px' }}>
            <p >Office Address</p>
          </div>
          <div className='contact__info'>
            <i class='fa fa-thumbtack' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
            <p>4th Floor Duki Building Office Suit 402, <br/> St. Yared Square, Addis Ababa</p>
          </div>
          <div className='contact__info'>
            <i class='fa fa-phone' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
            <p>Management : +251-911-513683</p>
          </div>
          <div className='contact__info'>
            <i class='fa fa-phone' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
            <p>Finance Dept : +251-911-140743</p>
          </div>
          <div className='contact__info'>
            <i class='fa fa-phone' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
            <p>Marketing : +251-965-299202</p>
          </div>
          <div className='contact__info'>
            <i class='fa fa-envelope' style={{ color: '#000', paddingRight: '10px', paddingTop: 3 }} />
            <p>Poiict.ethiopia@gmail.com</p>
          </div>
        </div>
        <div className='footer__item'>
          <div style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '5px' }}>
            <p>Follow Us</p>
          </div>
          <div className='social__medias'>
            <Link
              class='social-icon-link linkedin'
              to='/'
              target='_blank'
              aria-label='Linkedin'
            >
              <i class='fab fa-linkedin' />
            </Link>
            <Link
              class='social-icon-link telegram'
              to='/'
              target='_blank'
              aria-label='Telegram'
            >
              <i class='fab fa-telegram' />
            </Link>
            <Link
              class='social-icon-link whatsapp'
              to='/'
              target='_blank'
              aria-label='Whatsapp'
            >
              <i class='fab fa-whatsapp' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
          </div>
        </div>
      </div>
      <div className='footer__name'>
        <small>P.O.I Information Technology Services Pvt.Ltd.Co @ 2023</small>
      </div>
    </div>
  );
}

export default Footer;
