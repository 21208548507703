import React from "react";
import './OurEmployee.css'
function OurEmployee() {
    return (
        <div className="employee__container">
            <h1>Our Employees</h1>
            <div className="employee__wrapper">
                <div className="employee__list">
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Wondifraw Nigussie</p>
                            <p>CEO</p>
                        </div>
                    </div>
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Alazar G/selase</p>
                            <p>Enterprise Project Manager</p>
                        </div>
                    </div>
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Alemtsehay </p>
                            <p>Finanace and HR Manager</p>
                        </div>
                    </div>
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Tesfalet</p>
                            <p>Finanace Officer</p>
                        </div>
                    </div>
                </div>
                <div className="employee__list">
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Meseker Kidane</p>
                            <p>Software Project Manager</p>
                        </div>
                    </div>
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Biniyam Tekalign</p>
                            <p>Senior Software Developer</p>
                        </div>
                    </div>
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Etshiwot Eshetu</p>
                            <p>Senior Software Developer</p>
                        </div>
                    </div>
                    <div className="employee">
                        <div className="employee_img_wrapper">
                            <img src="/images/img-11.jpg" className="employee__img" />
                        </div>
                        <div className="employee__info">
                            <p>Abrham </p>
                            <p>Marketing Manager</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurEmployee