import React from 'react';
import './AboutCard.css'

function AboutCard() {
    return (
        <div className='about'>
            <h1>15+ YEARS of EXPERIANCE in the ICT INDUSTRY.</h1>
            <div className='about__container'>
                <div className='about__wrapper'>
                    <div className='about_items'>
                        <div className='about__info'>
                            <p>
                                <p style={{paddingBottom: 5}}>
                                    P.O.I Information Technology Services PLC stands out as a leading Information and Communication Technology
                                    Solutions provider in Ethiopia, specializing in Telecom & IT infrastructure since its establishment in 2006.
                                </p>
                                <p style={{paddingBottom: 5}}>
                                    Orginally founded by three Engineers, the company evolved under the leadership of Mr. Wondifraw Nigussie (PEE),
                                    Who is now the major shareholder and CEO.
                                </p>
                                <p style={{paddingBottom: 5}}>
                                    Initially focused on ICT Consultancy and Graphics Design, P.O.I has significantly expanded its portofolio, actively
                                    participating in diverse Telecom and Information Communication Technology projects. With over 80% of
                                    revenue generated from government contracts, the company has grown into a medium-sized enterprise.
                                </p>
                                <p style={{paddingBottom: 5}}>
                                    Driven by innovation, P.O.I has forged strategic partnerships with reowned international integrators,
                                    ensuring the successful delivery of high-tech solutions. As a key player in Ethiopia's tech landscape,
                                    P.O.I continues to elevate the standard of Information and Communication Technology Services.
                                </p>
                            </p>
                        </div>
                        <div className='about__img'>
                            <img src='/images/HomeAbout.jpg' />
                        </div>
                    </div>
                    <div className='company_info'>
                        <div className='about__item'>
                            <div className='about__company'>
                                <small>Envisioning a technologically empowered africa, our mission is to transform the
                                    continent through the convergence of innovation and dedication crafting a future of
                                    brighter and better lives for all.
                                </small>
                                <h1>Vision</h1>
                            </div>

                        </div>
                        <div className='about__item'>
                            <div className='about__company'>
                                <small>
                                    At our business's essence lies a profound belief: technology's potency shapes
                                    a future of enhanced lives. We're dedicated to transforming this conviction into
                                    tangible value, bringing brighter, better tomorrows to all through innovative solutions.
                                </small>
                                <h1>Mission</h1>
                            </div>

                        </div>
                        <div className='about__item'>
                            <div className='about__company'>
                                <ul>
                                    <li><small>Be excellence in whatever we do</small></li>
                                    <li><small>Be teamwork player</small></li>
                                    <li><small>Be responsible</small></li>
                                    <li><small>Be Commitment</small></li>
                                    <li><small>Recognize others effort</small></li>
                                </ul>
                                <h1>Core Values</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutCard