import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <img className='hero-img' src='/images/HomeHeader.png'  />
      <h2>Deliver Values Through Technology</h2>
    </div>
  );
}

export default HeroSection;
